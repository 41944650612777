import Link from "next/link";
import React from "react";

export type TargetProps = {
  type: "" | "href" | "entry" | "category" | "asset" | "url" | "email";
  // For href type
  href?: string;
  // For entry, category, and asset types
  element?: {
    uri: string;
    // Other possible element properties that might be used
    [key: string]: any;
  };
  // For asset, url, and email types
  url?: string;
  text?: string;
};

const Target = ({
  className,
  target,
  children,
  onClick = () => null,
  scroll = true,
  style = {},
}: {
  className?: string;
  target: TargetProps;
  children: React.ReactNode;
  onClick?: () => void;
  scroll?: boolean;
  style?: React.CSSProperties;
}) => {
  const returnElement = () => {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  };

  if (!target || target.type === "") {
    return returnElement();
  }

  if (target.type === "href" && !!target.href) {
    if (!target.href) return returnElement();

    return (
      <Link
        href={target.href}
        scroll={scroll}
        prefetch={false}
        className={className}
        draggable={false}
        onClick={onClick}
        style={style}
      >
        {children}
      </Link>
    );
  }

  // TODO: remove this once we update articles -> magazine in the cms
  if (target.type === "entry" && !!target.element) {
    let uri = target.element.uri;
    if (uri && uri.startsWith('articles/')) {
      uri = 'magazine/' + uri.substring('articles/'.length);
    }
    const href = `/${uri}`;

    if (!href) return returnElement();

    return (
      <Link
        href={href}
        scroll={scroll}
        prefetch={false}
        className={className}
        draggable={false}
        onClick={onClick}
        style={style}
      >
        {children}
      </Link>
    );
  }

  if (target.type === "category" && !!target.element) {
    const href = `/${target.element.uri}`;

    if (!href) return returnElement();

    return (
      <Link
        href={href}
        scroll={scroll}
        prefetch={false}
        className={className}
        draggable={false}
        onClick={onClick}
        style={style}
      >
        {children}
      </Link>
    );
  }

  if (target.type === "asset" && !!target.element) {
    return (
      <a
        href={target.url}
        className={className}
        draggable={false}
        download
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
        style={style}
      >
        {children}
      </a>
    );
  }

  if (target.type === "url") {
    return (
      <a
        href={target.url}
        className={className}
        target="_blank"
        rel="noreferrer"
        draggable={false}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  if (target.type === "email") {
    return (
      <a href={target.url} className={className}>
        {children}
      </a>
    );
  }

  return returnElement();
};

export default Target;

import clsx from "clsx";
import React from "react";

const Block = ({
  children = [],
  spaceAbove = "none",
  spaceBelow = "none",
  typeHandle = null,
  backgroundColor = "transparent",
}) => {
  // Space above classes mapping
  const spaceAboveClasses = {
    none: "pt-0",
    single: "pt-8 md:pt-8 lg:pt-12",
    double: "pt-12 md:pt-12 lg:pt-16",
    triple: "pt-16 md:pt-16 lg:pt-20",
  };

  // Space below classes mapping
  const spaceBelowClasses = {
    none: "pb-0",
    single: "pb-8 md:pb-8 lg:pb-12",
    double: "pb-12 md:pb-12 lg:pb-16",
    triple: "pb-16 md:pb-16 lg:pb-20",
  };

  // Determine the block classes
  const blockClasses = [
    "relative",
    spaceAboveClasses[spaceAbove],
    spaceBelowClasses[spaceBelow],
  ];

  // Add typeHandle-specific class if provided
  if (typeHandle) {
    blockClasses.push(`block-${typeHandle}`);
  }

  return (
    <div
      className={clsx("Block",`Block--${typeHandle}`,blockClasses.join(" "))}
      style={{ backgroundColor }}
    >
      {children}
    </div>
  );
};

export default Block;

import clsx from "clsx";
import Target from "components/Target";
import Image from "next/image";


export type CardProps = {
  id: string;
  image: {
    url: string;
    title: string;
  };
  heading: string;
  caption: string;
  target: typeof Target;
  size: "base" | "small";
  footer?: string;
  textOverlay?: boolean;
}

const Card = ({
  image,
  heading,
  caption,
  target,
  size = "base",
  footer,
  textOverlay = false,
}: CardProps) => {
  return (
    <div
      className={clsx(
        "Card",
        "relative flex h-full grow flex-col overflow-hidden rounded-lg shadow shadow-gray-400/10 transition-all hover:shadow-md hover:shadow-gray-400/10",
        "@container",
        {},
      )}
    >
      {!!image && (
        <Target
          className={clsx(
            "Card__imageWrapper",
            "relative block w-full shrink-0 group",
            {
              "aspect-4/3": !textOverlay,
              "h-full min-h-[500px]": textOverlay,
            },
          )}
          target={target}
        >
          {textOverlay && (
            <div className={clsx("absolute inset-0 z-10 bg-gray-900/60")}></div>
          )}
          <Image
            src={image.url}
            alt={image.title}
            className={clsx(
              "Card__image",
              "transition duration-100 ease-out group-hover:scale-[1.0125] object-cover",
            )}
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
        </Target>
      )}

      <div
        className={clsx(
          "Card__content",
          "flex h-full grow flex-col",
          {
            "relative bg-white": !textOverlay,
            "absolute top-0 z-20 text-white": textOverlay,
          },
        )}
      >
        <_Content
          size={size}
          target={target}
          heading={heading}
          textOverlay={textOverlay}
          caption={caption}
          footer={footer}
        />
      </div>
    </div>
  );
};

const _Content = ({ size, target, heading, textOverlay, caption, footer }) => {
  return (
    <>
      <div className={clsx("py-6 flex-1 flex flex-col gap-y-2 @lg:gap-y-4")}>
        <Target
          className={clsx("Card__heading", "block font-medium px-6", {
            "text-xl md:text-2xl": size === "base",
            "text-base sm:text-lg": size === "sm" || size === "small",
          })}
          target={target}
        >

          {heading}
        </Target>
        {caption &&
        <div
          className={clsx(
            "Card__caption",
            "prose prose-lg @md:prose-xl",
            "grow prose-strong:font-medium px-6 line-clamp-5",
            {
              "text-gray-500": !textOverlay,
              "text-white prose-strong:text-white": textOverlay,
            },
          )}
          dangerouslySetInnerHTML={{ __html: caption }}
        />}
      </div>
      {!!footer && (
        <Target
          className={clsx(
            "Card__footer",
            "py-4 px-6 border-t border-t-gray-150",
            {
              "text-gray-500/75 hover:text-gray-600": !textOverlay,
              "hover:text-white-600  text-white/75": textOverlay,
              "text-base": size === "base",
              "text-sm": size === "sm" || size === "small",
            },
          )}
          target={target}
        >
          {footer}
        </Target>
      )}
    </>
  );
};

export default Card;

import clsx from "clsx";
import React, { cloneElement } from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";

const transform = (node, index) => {
  // Intercept a tags
  if (node.name === "a") {
    // Convert to a react element
    const element = convertNodeToElement(node, index, transform);

    // Clone, appending class and styles
    return cloneElement(element, {
      className: clsx(
        "font-normal underline underline-offset-4 decoration-1 decoration-gray-400 hover:decoration-gray-700",
      ),
    });
  }

  // Intercept strong tags
  if (node.name === "strong") {
    // Convert to a react element
    const element = convertNodeToElement(node, index, transform);

    // Clone, appending class and styles
    return cloneElement(element, {
      className: clsx("font-medium"),
    });
  }

  // Intercept strong tags
  if (node.name === "em") {
    // Convert to a react element
    const element = convertNodeToElement(node, index, transform);

    // Clone, appending class and styles
    return cloneElement(element, {
      className: clsx("italic"),
    });
  }

  // Intercept p tags
  if (node.name === "p") {
    // Convert to a react element
    const element = convertNodeToElement(node, index, transform);

    // Clone, appending class and styles
    return cloneElement(element, {
      className: clsx("mb-5 prose prose-2xl lg:prose-3xl last-of-type:mb-0"),
    });
  }
};

const Standfirst = ({ standfirst }) => {
  return (
    <div className={clsx("Standfirst")}>
      <div
        className={clsx(
          "Standfirst_content",
          "lg:font-light text-gray-900",
        )}
      >
        <div>{ReactHtmlParser(standfirst, { transform })}</div>
      </div>
    </div>
  );
};

export default Standfirst;
